import * as ElchspuckeService from '../../elchspucke-tuicom.service';
import { isLegacyFont } from '../../legacyFont';

const injectFavorites = (awsCdnUrl, tenant) => {
    Promise.all([injectFavoritesHeart(awsCdnUrl), injectFavoritesPopup(awsCdnUrl, tenant)]).then(() => {
        // Wait for the next event loop to end before sending the event
        setTimeout(() => {
            const currentUser = ElchspuckeService.getCurrentUser();

            if (currentUser && currentUser.claims) {
                document.dispatchEvent(
                    new CustomEvent('tui-favorites.AuthenticationStateChanged', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            token: currentUser.token || null
                        }
                    })
                );
            }
        });
    });
};

const injectFavoritesHeart = (awsCdnUrl) => {
    const sourceTag = document.querySelector('script[id=tui-favorites-heart-script]');

    if (!sourceTag) {
        return new Promise((resolve) => {
            const favoritesHeartScript = document.createElement('script');

            favoritesHeartScript.src = `${awsCdnUrl}favorites/current/tui-favorites-heart.js`;
            favoritesHeartScript.setAttribute('type', 'module');
            favoritesHeartScript.setAttribute('id', 'tui-favorites-heart-script');
            favoritesHeartScript.onload = () => resolve();
            favoritesHeartScript.onerror = () => resolve();

            document.head.appendChild(favoritesHeartScript);
        });
    } else {
        return Promise.resolve();
    }
};

const injectFavoritesPopup = (awsCdnUrl, tenant) => {
    return new Promise((resolve) => {
        const favoritesPopupScript = document.createElement('script');

        favoritesPopupScript.src = `${awsCdnUrl}favorites/current/tui-favorites-popup.js`;
        favoritesPopupScript.setAttribute('type', 'module');
        favoritesPopupScript.onload = function () {
            var tag = document.createElement('tui-favorites-popup');
            tag.setAttribute('id', 'tui-favorites-popup');
            tag.setAttribute('tenant', tenant);
            tag.setAttribute('data-theme', 'tui-light');
            if(isLegacyFont()) {
                tag.setAttribute('data-legacy-font', '');
            }
            tag.setAttribute('locale', ElchspuckeService.getPageLocale());
            document.body.appendChild(tag);

            document.addEventListener('mousemove', (event) => {
                setMousePosition(event);
            });

            resolve();
        };

        document.head.appendChild(favoritesPopupScript);
    });
};

let mousePosition = [0, 0];
const setMousePosition = (event) => {
    mousePosition = [event.clientX, event.clientY];
};

const addHotel = (hotelCardsData, tenant) => {
    const tenantMapping = {
        TUICOM: 'tui.com',
        TUICH: 'tui.ch',
        TUIAT: 'tui.at'
    };
    let searchParameters = {};
    if (window.ibe) {
        // neusta event for getting data
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', function (cmpName, scope, evtName, ibeState) {
            searchParameters = transformSearchParamsFromIbeFilterState(ibeState.filters);
            if (searchParameters) {
                searchParameters.giatas = [hotelCardsData.giataId];
                searchParameters.transferIncluded = false;
                searchParameters.tenant = tenantMapping[tenant];
                let listname = 'Merkzettel';
                if (ibeState.region && ibeState.region.name) {
                    listname = ibeState.region.name;
                }
                openAddHotel(searchParameters, listname);
            }
        });
    } else {
        //no IBE present on the page
        searchParameters = transformSearchParamsFromHotelOfferCards(hotelCardsData);
        if (searchParameters) {
            searchParameters.giatas = [hotelCardsData.giataId];
            searchParameters.transferIncluded = false;
            searchParameters.tenant = tenantMapping[tenant];
            let listname = 'Merkzettel';
            if (hotelCardsData.hotel && hotelCardsData.hotel.location && hotelCardsData.hotel.location.region) {
                listname = hotelCardsData.hotel.location.region;
            }
            openAddHotel(searchParameters, listname);
        }
    }
};

const removeHotel = (cmpName, scope, evtName, data) => {
    const popup = document.getElementById('tui-favorites-popup');
    popup.removeHotel({ giataId: data.giataId });
};

const openAddHotel = (searchParameters, listname) => {
    let clickedElement = document.elementFromPoint(mousePosition[0], mousePosition[1]);

    if (clickedElement.tagName.toLowerCase() === 'i') {
        clickedElement = clickedElement.parentElement;
    }

    const rect = clickedElement.getBoundingClientRect();
    const popup = document.getElementById('tui-favorites-popup');
    const popupWidth = 300;

    popup.addHotel(
        {
            giataId: searchParameters.giatas[0],
            searchParams: JSON.stringify(searchParameters),
            placeholderListName: listname
        },
        // Align right with heart icon
        [rect.x + rect.width - popupWidth, rect.y],
        popupWidth
    );
};

const transformSearchParamsFromIbeFilterState = (data) => {
    if (!data || typeof data !== 'object') {
        return false;
    }

    const mappedSearchParams = ElchspuckeService.mapPTH4SearchParams(data);

    const searchParamsForFavorites = {
        searchScope: data.searchScope,
        startDate: mappedSearchParams.startDate,
        endDate: mappedSearchParams.endDate,
        duration: mappedSearchParams.durations,
        rooms: mappedSearchParams.rooms,
        airports: mappedSearchParams.departureAirportCodes
    };

    return searchParamsForFavorites;
};

const transformSearchParamsFromHotelOfferCards = (data) => {
    if (!data || typeof data !== 'object') {
        return false;
    }

    const searchParamsForFavorites = {
        searchScope: data.searchScope,
        startDate: data.departureDate,
        endDate: data.returnDate,
        duration: goAndHandleHotelCardsDuration(data.durations),
        rooms: goAndHandleHotelCardsRooms(data.adults, data.childs, data.boardTypes, data.roomTypes),
        airports: data.departureAirportCodes
    };

    return searchParamsForFavorites;
};

const goAndHandleHotelCardsDuration = (durations) => {
    if (!durations || typeof durations !== 'string' || durations.length < 1) {
        return [7];
    }
    return durations.split('-').map(Number);
};

const goAndHandleHotelCardsRooms = (adults, children, roomTypes, boardTypes) => {
    const rooms = [];

    if (!adults || typeof adults !== 'string' || adults.length < 1) {
        rooms.push({ numberOfAdults: 2 });
        return rooms;
    }

    const adultTravellers = adults.split(',');
    const childrenTravellers = children && typeof children === 'string' ? children.split(',') : '';

    for (let i = 0; i < adultTravellers.length; i++) {
        const numberOfAdults = parseInt(adultTravellers[i], 10);

        const room = {};
        room.numberOfAdults = numberOfAdults;

        if (childrenTravellers[i]) {
            room.childAges = childrenTravellers[i].split(';').map(Number);
        }

        // for the time being room codes and board codes can only be set on a per travel base
        if (roomTypes && roomTypes instanceof Array) {
            room.roomCodes = roomTypes;
        }
        if (boardTypes && boardTypes instanceof Array) {
            room.boardCodes = boardTypes;
        }

        rooms.push(room);
    }

    return rooms;
};

const openFavorites = (tenant) => {
    const contentid = 'merkzettel';
    if (tenant === 'TUICH') {
        if (ElchspuckeService.getPageLanguage() === 'fr') {
            window.location.href = `/fr/memo?contentid=${contentid}`;
        } else {
            window.location.href = `/de/merkzettel?contentid=${contentid}`;
        }
    } else {
        window.location.href = `/merkzettel?contentid=${contentid}`;
    }
};

const openFavoritesList = (tenant, listId) => {
    const contentid = 'merkzettel';
    if (listId) {
        if (tenant === 'TUICH') {
            if (ElchspuckeService.getPageLanguage() === 'fr') {
                window.location.href = `/fr/memo?listId=${listId}&contentid=${contentid}`;
            } else {
                window.location.href = `/de/merkzettel?listId=${listId}&contentid=${contentid}`;
            }
        } else {
            window.location.href = `/merkzettel?listId=${listId}&contentid=${contentid}`;
        }
    }
};

const getHotelData = (hotels) => {
    let allHotels = [];
    hotels.forEach((hotel) => {
        allHotels.push({
            hotel_name: hotel?.name,
            giata: hotel?.giataId,
            hotel_productcode: hotel?.bestOffer?.productCode,
            destination_city: hotel?.city,
            destination_country: hotel?.countryName,
            destination_region: hotel?.regionName,
            product_price: hotel?.bestOffer?.price?.total,
            days_travel_duration: hotel?.bestOffer?.travelPeriod?.travelDuration,
            date_departure: hotel?.bestOffer?.travelPeriod?.travelStartDate
        });
    });
    return allHotels;
};

const getHotel = (giata, hotels) => {
    for(let i = 0; i <= hotels.length; i++) {
        if (giata === hotels[i].giata) {
            return hotels[i];
        }
    }
};

const trackEvent = (link_type, link_category, action, link_label, hotel_name, hotel_productcode, destination_city, destination_country, destination_region, product_price, days_travel_duration, date_departure, destination_code, hotel_url) => {
    if (!window.utag || !window.utag.link || typeof window.utag.link !== 'function') {
        return false;
    } else {
        window.utag.link({ 
            link_type: link_type, 
            link_category: link_category, 
            link_action: action, 
            link_label: link_label ? link_label : 1,
            hotel_name,
            hotel_productcode,
            destination_city,
            destination_country,
            destination_region,
            product_price,
            days_travel_duration,
            date_departure,
            destination_code,
            hotel_url
        });
    }
    return true;
};

export { injectFavorites, addHotel, removeHotel, openFavorites, openFavoritesList, getHotelData, getHotel, trackEvent };
