import { goAndHandlePTH4Durations } from '../../elchspucke-tuicom.service';
import { formatDate, loadScripts } from '../../util';
import { isLegacyFont } from '../../legacyFont';

export default function (config) {
    
    document.addEventListener('tui-auto-offer.connected', ({ detail }) => {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            const filter = ibeState.filters;
            const travellers = ibeState.filters.travellers.map((room) => { return {numberOfAdults: room.adults, childAges: room.children };});
            const searchParameters = {
                searchScope: filter.searchScope,
                startDate: formatDate(filter.startDate),
                endDate: formatDate(filter.endDate),
                airports: filter.departureAirports,
                duration: goAndHandlePTH4Durations(filter.duration, filter.startDate, filter.endDate),
                rooms: travellers
            };

            detail.callback(searchParameters, ibeState.hotel.giata, 'HOC_AO');
        });
    });

    document.addEventListener('tui-auto-offer.newsletter-register', () => {
        const contentid = 'auto-offer_newsletter';
        window.location.href = `/newsletter-anmeldung?contentid=${contentid}`;
    });

    document.addEventListener('tui-auto-offer.share-with-agency', ({ detail }) => {
        const contentid = 'auto-offer_agency';
        if (detail) {
            const mapLocation = {
                location: detail.location,
                zoom: 10
            };
            sessionStorage.setItem('travelAgencyFinderMapLocation', JSON.stringify(mapLocation));
        } else {
            sessionStorage.removeItem('travelAgencyFinderMapLocation');
        }
        window.location.href = `/reisebuero?contentid=${contentid}`;
    });

    document.addEventListener('tui-auto-offer.consentApprovalEvent', (event) => {
        loadScripts([`${config.getAWSCdnUrl()}consent/current/consent.js`,])
            .then(() => {
                document.dispatchEvent(new CustomEvent('tui-consent.is-approved', {
                    detail: {
                        cb: (approved) => {
                            if (!approved) {
                                const consent = document.createElement('tui-consent');
                                consent.setAttribute('data-theme', 'tui');
                                if(isLegacyFont()) {
                                    consent.setAttribute('data-legacy-font', '');
                                }
                                consent.setAttribute('data-btn-size', 'small');
                                event.detail.searchNode.parentNode.insertBefore(consent, event.detail.searchNode);
                                consent.addEventListener('tui-consent.consent-approved', () => {
                                    event.detail.listenerNode.dispatchEvent(new CustomEvent('tui-auto-offer.consent-approved'));
                                    consent.remove();
                                })
                            } else {
                                event.detail.listenerNode.dispatchEvent(new CustomEvent('tui-auto-offer.consent-approved'));
                            }
                        }
                    }
                }));
            })
    });
}
